import { Login } from '../../containers/WebApp/Login/Login';

export default Login

export const query = graphql`
query LoginQuery($language: String!) {
  locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;